<template>
  <Hero :title="data?.header.title" :subtitle="data?.header.subtitle" :narrow="true" :images="data?.header.images"/>
  <main class="main maxw--neck block">
    <form @submit.prevent="submit" class="form">
      <fieldset name="general" class="form__fieldset">
        <div class="form__row">
          <FormTextField name="email" type="email" label="Email" v-model="v$.email.$model" :validator="v$.email"/>
        </div>
        <div class="form__row">
          <FormTextField name="password" type="password" label="Wachtwoord" v-model="v$.password.$model" :validator="v$.password"/>
        </div>
        <div class="form__row">
          <div class="field">
            <button class="btn btn--full mt-2" type="submit" :disabled="isLoading">
              Inloggen
              <Spinner v-if="isLoading" />
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    <div class="vertical-center mt-2">
      <nuxt-link to="/registreren" class="btn btn--flat">Ik heb nog geen account</nuxt-link>
      <nuxt-link to="/wachtwoord-vergeten" class="btn btn--flat">Wachtwoord vergeten</nuxt-link>
    </div>
  </main>
</template>

<script lang="ts" setup>
import useVuelidate, { ServerErrors } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import LoginPage from '~/typings/pages/login_page';

const { isLoading, startLoading, stopLoading } = useLoading();
const { findOne } = useStrapi();
const { login } = useStrapiAuth();
const router = useRouter();

useHead({
  title: 'Inloggen'
});

definePageMeta({
  middleware: 'unprotected'
});

const { data } = await useAsyncData(
  'loginpage',
  () => findOne<LoginPage>('login-page', { populate: ['header', 'header.images'] }),
  {
    transform: flattenStrapiObject
  }
);

const baseFormData = {
  email: '',
  password: ''
};

const formData = reactive({...baseFormData});

const $externalResults = ref<ServerErrors>({});

async function submit(e: SubmitEvent) {
  if (!await v$.value.$validate() || isLoading.value) return;

  startLoading();

  try {
    const response = await login({ identifier: formData.email, password: formData.password });

    const passwordChanged = response.user.value?.passwordChanged || false;

    Object.assign(formData, baseFormData);
    v$.value.$reset();

    router.push('/account' + (passwordChanged ? '' : '/wachtwoord-wijzigen?initial=true'));
  } catch (e: any) {
    const errors = [];

    if (e?.error?.name === "ValidationError") {
      errors.push('Ongeldig e-mailadres of wachtwoord');
    } else {
      errors.push('Er ging iets fout, probeer het later opnieuw');
    }

    $externalResults.value = {
      password: errors
    }
  } finally {
    stopLoading();
  }
}

const rules = computed(() => ({
  email: {
    required: helpers.withMessage('Dit veld is verplicht', required)
  },
  password: {
    required: helpers.withMessage('Dit veld is verplicht', required)
  }
}));

const v$ = useVuelidate(rules, formData, { $externalResults });
</script>
